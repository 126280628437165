//
// This classs handles the websocket connection to the Centrifugo server.
//
// If there is a `centrifugo-connection-token` meta tag then it will use that token
// to connect to Centrifugo server when `subscribe` method is called for the first time.
//
// If there is no such token then `undefined` is exposed instead. That token is only present
// on the student layout when there is a user logged in.
//

import Centrifuge from "centrifuge";

class Centrifugo {
  constructor(url, connection_token) {
    this.client = new Centrifuge(url);
    this.client.setToken(connection_token);
  }

  subscribe(channel, handler) {
    this.connect();
    return this.client.subscribe(channel, handler);
  }

  unsubscribe(subscription) {
    subscription.unsubscribe();
    subscription.removeAllListeners();
  }

  connect() {
    if (!this.client.isConnected()) {
      this.client.connect();
    }
  }
}

var instance;
var public_url_meta = document.querySelector('meta[name="centrifugo-public-url"]');
var token_meta = document.querySelector('meta[name="centrifugo-connection-token"]');

if (public_url_meta && token_meta) {
  var public_url = public_url_meta.getAttribute("content");
  var connection_url = `${public_url}/connection/websocket`;
  var token = token_meta.getAttribute("content");

  instance = new Centrifugo(connection_url, token);
}

export default instance;
